.DraftEditor-editorContainer {
  height: auto;
}
.DraftEditor-root {
  height: 40vh;
  border: 1px solid #f2f2f2;
  overflow: auto;
  padding: 15px;
}
.rdw-image-modal-btn,.rdw-link-modal-btn{
  padding:0;font-size: 15px;
}