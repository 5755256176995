.progress-div {
  /* background-color: rgb(233, 233, 233); */
  background-color: transparent;
  border-radius: 0.5rem;
}

.progress {
  background-color: #d8ae36;
  height: 4px;
  border-radius: 1rem;
  transition: 1.8s ease;
  transition-delay: 0.5s;
}
