body.ReactModal__Body--open {
  overflow: hidden;
}

.modal {
  position: fixed;
  box-sizing: border-box;
  max-width: 100vw;
  overflow: hidden;
  padding: 5px 0 0;
  background: #fff;
  outline: none;
  border-radius: 5px;
  max-height: 100vh;
  /* opacity: 0; */
}
.modal .header-logo {
  width: auto;
  max-height: 35px;
  max-width: 100%;
  margin: 15px 0 15px 30px;
}

.ReactModal__Content .modal-header {
  padding: 15px 25px;
  text-transform: capitalize;
  border-bottom: 5px solid #f4f5f9;
  display: flex;
  font-size: 1.1em;
  font-weight: 550;
  align-items: center; 
}

.ReactModal__Content .modal-header .backIcon {
  cursor: pointer;
  font-size: 1.3rem;
  padding-right: 10px;
}

.ReactModal__Content .modal-body {
  padding: 15px;
  max-height: calc(100vh - 90px);
  overflow: overlay;
}

/*===== MODAL OVERLAY STYLES=========*/
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  /* width: 100vw;
  height: 100vh;  */
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
}
.ReactModal__Overlay {
  opacity: 0;
  /* transition: opacity 500ms ease-in-out; */
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}

/*===== MODAL CENTER STYLES=========*/
.modalCentered {
  right: auto;
  left: 50%;
  top: 50%;
  bottom: auto;
  margin-right: -50%;
  height: auto;
}
.ReactModal__Content.modalCentered {
  opacity: 0;
  transform: translate(50%, 50%);
  transition: opacity 0.2s ease-in-out;
  /* transition: all .3s ease-in-out; */
  /* transition:opacity  linear 0.15s */
}
.modalCentered.ReactModal__Content--after-open {
  opacity: 1;
  transform: translate(-50%, -50%);
  /* transform: translate(0,0); */
}
.modalCentered.ReactModal__Content--before-close {
  opacity: 0;
  transform: translateX(50%, 50%);
  /* transform: translate(-50%,-50); */
}

/*===== MODAL RIGHT STYLES=========*/
.modalRight {
  right: 0;
  top: 0;
  left: auto;
  bottom: 0;
  height: 100vh;
  border-radius: 0px !important;
}
.modalRight h3 {
  font-size: 20px;
  margin: 20px 15px;
}
.ReactModal__Content.modalRight {
  opacity: 0;
  transform: translate(25%, 0);
  /* transition: all 500ms ease-in-out; */
  transition: transform 0.3s ease-out;
}
.modalRight.ReactModal__Content--after-open {
  opacity: 1;
  transform: translate(0, 0);
}
.modalRight.ReactModal__Content--before-close {
  opacity: 0;
  transform: translate(25%, 0);
}
.ReactModal__Content.modalRight .modal-body {
  height: calc(100vh - 70px);
  overflow: overlay;
}

/*===== MODAL BOTTOM STYLES=========*/
.modalBottom {
  bottom: 0;
  width: 100vw;
  border-radius: 0px !important;
}
.ReactModal__Content.modalBottom {
  opacity: 0;
  transform: translate(0, 25%);
  /* transition: all 500ms ease-in-out; */
  transition: transform 0.3s ease-out;
}
.modalBottom.ReactModal__Content--after-open {
  opacity: 1;
  transform: translate(0, 0);
}
.modalBottom.ReactModal__Content--before-close {
  opacity: 0;
  transform: translate(0, 25%);
}
