.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
  display: block;
  margin: 0px;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 37px;
  height: 20px;
  background: #fff;
  border-radius: 100px;
  border: 1px solid #e2e2e2;
  position: relative;
  transition: background-color 0.2s;
  padding: 0px;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 45px;
  transition: 0.2s;
  background: #e2e2e2;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
  background: #d8ae36;
}

.react-switch-label:active .react-switch-button {
  width: 33px;
}

.switchClassTitle {
  display: inline-block;
  max-width: 75%;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 18px;
  padding: 0;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  width: 45px;
  height: 22px;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 1px;
  top: 2px;
  width: 18px;
  height: 18px;
  background-color: #e2e2e2;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(25px);
  background-color: #d8ae36;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #fff;
  border: 1px solid #e2e2e2;
}
