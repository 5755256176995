#dashboard .MuiSvgIcon-fontSizeLarge{
    /* float: right; */
    font-size: 1.2875rem;
    color: #f3f2f8;
    background: #3f97ff;
    padding: 5px;
    border-radius: 5px; 
    margin-top: 4px;
}
#dashboard small{
    font-size: 0.9rem;
    font-weight: 400;
} 
#dashboard .MuiTableCell-root{
    padding: 12px 9px;
}  
@import url(../css/highcharts-mode.css);